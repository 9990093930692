import { ReactComponent as OrderIcon } from '../../../assets/icons/orders.svg'
import './style.scss'

const IconTextWideBtn = ({ Icon = OrderIcon, desc = 'Twoje zamówienia', clickAction }) => {
  return (
    <button className='button__icontext--wide'
      onClick={clickAction}>
      <Icon />
      <div className='button__description'>{desc}</div>
    </button>
  )
}

export default IconTextWideBtn