export const serverUrl = process.env.REACT_APP_API || 'https://api.dostolika.pl/'
const api = `${serverUrl}api/`
const products = 1

export const categories = screen_id => `${api}categories?screen_id=${screen_id}&products=${products}`
export const cart = `${api}cart`
export const currentOrders = `${api}currentorders`

export const payment = `${api}payment/init`
export const paymentCancel = `${api}payment/cancel`

export const findCompany = nip => `${api}company/find/${nip}`

const functions = `${api}functions`
export const rate = `${functions}/rate`
export const chat = `${functions}/chat`
export const terms = `${functions}/terms`
export const config = `${functions}/config`
export const policy = `${functions}/policy`
export const status = `${functions}/status`
export const sendtip = `${functions}/sendtip`
export const banklist = `${functions}/banklist`
export const gpayconfig = `${functions}/gpaytoken`
export const checknumber = `${functions}/checknumber`
export const updatetable = `${functions}/updatetable`
export const checktablecode = `${functions}/checktablecode`
export const christmasorder = `${functions}/christmasorder`
export const christmasorders = `${functions}/christmasorders`
